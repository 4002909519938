﻿/*
 *  Document   : _extra_pages.scss
 *  Author     : RedStar Theme
 *  Description: This scss file for extra pages like sign in, sign up etc.. style classes
 */

 .txt1 {
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-SemiBold";
  font-size: 12px !important;
  line-height: 17px;
  color: #0F79AF;
  text-decoration: underline;
  
}
.txt1:hover{
  color: #4B4B4B;
}

.txt2 {
  // font-family: "SourceSansPro-Regular";
  font-family: "SourceSansPro-Regular" !important;
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}

.size1 {
  width: 355px;
  max-width: 100%;
}

.size2 {
  width: calc(100% - 43px);
}

.bg1 {
  background: #3b5998;
}

.bg2 {
  background: #1da1f2;
}

.bg3 {
  background: #cd201f;
}
p{
  color: #4B4B4B;
}
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  // padding: 30px;
}

.wrap-login100 {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}

.login100-more {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.login100-more::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.login100-form {
  width: 453px;
  display: inline-table;
  background-color: #ffffff;
  padding: 34px 55px;
  margin: 30px;
  border: 1px solid #cacaca;
  border-radius: 20px;
}

.login100-form-title {
  font-size: 30px;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  color: #403866;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
}

.error-header {
  font-size: 80px;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  color: #403866;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
}

.error-subheader {
  font-size: 17px;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  color: #403866;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
}

.error-subheader2 {
  font-size: 12px;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  color: #919192;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
}

.wrap-input100 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 60px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 10px;
}

.label-input100 {
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  font-size: 14px;
  color: #999999;
  line-height: 1.2;
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 24px;
  left: 0;
  top: 21px;
  transition: all 0.4s;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 16px !important;

  &:focus {
    height: 48px;
    box-shadow: none !important;

    + .focus-input100 {
      visibility: visible;
      opacity: 1;
      transform: scale(1);

      + .label-input100 {
        top: 7px;
        font-size: 13px;
      }
    }
  }
}

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #6675df;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  transform: scaleX(1.1) scaleY(1.3);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.has-val {
  height: 48px !important;

  + .focus-input100 + .label-input100 {
    top: 7px;
    font-size: 13px;
  }
}

.input-checkbox100 {
  display: none;

  &:checked + .label-checkbox100::before {
    color: #6675df;
  }
}

.label-checkbox100 {
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular" !important;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: '\f007';
  font-family: Font awesome\5 Free;
  font-size: 13px;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #6675df;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 40px;
  border: 2px solid #0F79AF !important;
  border-radius: 6px;
  background-color: #0f79af !important;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular";
  font-size: 14px !important;
  color: #fff !important;
  line-height: 1.2;
  // text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    
    background-color: #ffffff !important;
    color: #0F79AF !important;
  }
}

.login-btn-wrapper{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.5s;
}

.login-btn-wrapper:after {
  content: '\2192';
  position: absolute;
  opacity: 0;  
  top: 0px;
  // right: 50%;
  transition:all  0.5s;
}

// .login-btn-wrapper:hover{
//   padding-right: 50%;
// }

.login-btn-wrapper:hover:after {
  opacity: 1;
  right: 10px;
}

@media (max-width: 992px) {
  .login100-form {
    width: 100%;
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login100-more {
    width: 50%;
  }

  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .login100-form {
    width: 100%;
    display: block;
  }

  .login100-more {
    display: none;
  }
}

@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }
}

.validate-input {
  position: relative;

  .erroe_dis {
    display: none;
  }
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  z-index: 100;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  // font-family: 'Roboto', sans-serif;
  font-family: "SourceSansPro-Regular";
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: '\f12a';
  font-family: Font awesome\5 Free;
  display: block;
  position: absolute;
  z-index: 110;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  font-weight: 600;
  transform: translateY(-50%);
  right: 18px;
}

.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;

  &:hover {
    background: #333333;
    color: #fff;
  }
}

.flex-w {
  display: flex;
  flex-wrap: wrap;
}

.flex-l {
  display: flex;
  justify-content: flex-start;
}

.flex-r {
  display: flex;
  justify-content: flex-end;
}

.flex-c {
  display: flex;
  justify-content: center;
}

.flex-sa {
  display: flex;
  justify-content: space-around;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.flex-t {
  display: flex;
  align-items: flex-start;
}

.flex-b {
  display: flex;
  align-items: flex-end;
}

.flex-m {
  display: flex;
  align-items: center;
}

.flex-str {
  display: flex;
  align-items: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-rev {
  display: flex;
  flex-direction: row-reverse;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-rev {
  display: flex;
  flex-direction: column-reverse;
}

.flex-c-m {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-c-t {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-c-b {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-c-str {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.flex-l-m {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-r-m {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-sa-m {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-sb-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input.input100 {
  height: 100%;
  transition: all 0.4s;
  margin-bottom: 0 !important;
  font-size: 14px !important;
  padding-left: 25px !important;
  padding-top: 14px !important;
  border-bottom: none !important;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

// login register
.loginmain {
  background: #f1f2f7;
}

.site-link {
  padding: 5px 15px;
  position: fixed;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  right: 30px;
  bottom: 30px;
  border-radius: 10px;

  img {
    width: 30px;
    height: 30px;
  }
}

.loginCard {
  width: 70%;
  margin: 10% auto;
  // background-image: url("../../../assets/images/bg-01.jpg");
  min-height: 400px;
  position: relative;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);

  > div {
    text-align: center;
    color: #fff;
    width: 50%;
    left: 0;
    top: 40%;
    position: absolute;
  }

  .rgstr-btn {
    float: right;
    right: 0;
    left: auto;
  }

  button {
    background: transparent;
    display: inline-block;
    padding: 10px 30px;
    border: 3px solid #fff;
    border-radius: 50px;
    background-clip: padding-box;
    position: relative;
    color: #fff;
    transition: all 0.25s;

    &.dark {
      border-color: #bd59d4;
      background: #bd59d4;
      box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -moz-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -webkit-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -o-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -ms-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    }

    &:active {
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }
  }

  .wrapper {
    > form {
      padding: 15px 30px 30px;
      transition: all 0.5s;
      background: #fff;
      width: 100%;
      border-radius: 20px;

      &:focus {
        outline: none;
      }

      > div {
        position: relative;
        margin-bottom: 15px;
      }
    }

    #login {
      padding-top: 20%;
      visibility: visible;
    }

    #register {
      transform: translateY(-80%) translateX(100%);
      visibility: hidden;
    }

    &.move {
      #register {
        transform: translateY(-100%) translateX(0%);
        visibility: visible;
      }

      #login {
        transform: translateX(-100%);
        visibility: hidden;
      }
    }

    label {
      position: absolute;
      top: -7px;
      font-size: 12px;
      white-space: nowrap;
      background: #fff;
      text-align: left;
      left: 15px;
      padding: 0 5px;
      color: #999;
      pointer-events: none;
    }

    h3 {
      margin-bottom: 25px;
    }

    input {
      height: 40px;
      width: 100%;
      border: solid 1px #999;
      border-radius: 20px;
      padding-left: 10px;

      &:focus {
        outline: none;
        border-color: #ff4931;
        box-shadow: none !important;
        border-bottom: 1px solid #ff4931 !important;
      }
    }
  }

  .move button.dark {
    border-color: #bd59d4;
    background: #bd59d4;
    box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
  }

  .splits p {
    font-size: 18px;
  }

  > .wrapper {
    position: absolute;
    width: 40%;
    height: 120%;
    top: -10%;
    left: 5%;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    transition: all 0.5s;
    color: #303030;
    overflow: hidden;

    &.move {
      left: 45%;
    }
  }
}

@media (max-width: 767px) {
  .loginCard {
    padding: 5px;
    margin: 0;
    width: 100%;
    display: block;

    > div {
      display: inline-block;
    }

    > .wrapper {
      position: relative;
      height: auto;
      top: 0;
      left: 0;
      width: 100%;

      &.move {
        left: 0;
      }
    }
  }

  .splits {
    width: 50%;
    background: #fff;
    float: left;

    button {
      width: 100%;
      border-radius: 0;
      background: #505050;
      border: 0;
      opacity: 0.7;

      &.active {
        opacity: 1;
        background: #ff4931;
      }
    }

    p {
      display: none;
    }

    &.rgstr-btn button.active {
      background: #e0b722;
    }
  }
}

input:-webkit-autofill {
  box-shadow: inset 0 0 0 50px #fff;
}

textarea:-webkit-autofill {
  box-shadow: inset 0 0 0 50px #fff;
}

select:-webkit-autofill {
  box-shadow: inset 0 0 0 50px #fff;
}

.flex-c {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.login100-social-item {
  font-size: 25px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin: 5px;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
}

.login100-form-logo {
  font-size: 60px;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 auto;

  img {
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
}

.bg-fb {
  background-color: #3b5998;
}

.bg-twitter {
  background-color: #1da1f2;
}

.bg-google {
  background-color: #ea4335;
}
.show-pwd-icon {
  color: #000000;
}
.cstm-form-filed{
  .mat-form-field-infix{
    padding:8px 0px;
    border-top: 5px solid rgba(0,0,0,0);
    font-size: 11px !important;
    font-family: "SourceSansPro-Regular";

  }
}
.cstm-form-wrapper{
  .mat-form-field-wrapper{
    padding-bottom:10px;
    border-top: 5px solid rgba(0,0,0,0);
    font-family: "SourceSansPro-Regular";
  }
}
.cstm-form-wrapper{
  .mat-error {
    margin-top: 3px;
}
}
.login-page {
  width: 50%;
  background: #F6FBFF;
  display: grid;
  place-items: center;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
}
.form {
  position: relative;
  z-index: 1; 
  max-width: 360px;
  margin: 0 auto 0px;
  padding: 30px 20px;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #0000001A;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
.signin-heading h2{
  font-size: 24px;
  letter-spacing: 0px;
  color: #4B4B4B;
  opacity: 1;
  margin: 28px 0 0 0;
  padding-bottom: 10px;
  font-weight: 600;
  line-height: 24px;
  font-family: "SourceSansPro-Bold";
}
.signin-heading p{
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #4B4B4B;
  margin-bottom: 40px;
  line-height: 16px;
  font-family: "SourceSansPro-Regular";
}
.note-wrapper{
  font-size: 14px;
  color: #4B4B4B;
  line-height: 20px;
  font-family: "SourceSansPro-Regular"; 
}
.auth-banner-text{
  display: flex;
  align-items: center;
  padding: 40px;
  
}
.auth-banner-text::before{
  background-color: rgba(0, 0, 0, 0.3);
}
.auth-banner-info{
  border: 2px solid #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #0000001A;
  padding: 20px 19px;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}
.auth-banner-info p{
  font-size: 32px;
  color: #ffffff;
  letter-spacing: 0.58px;
  line-height: 49px;
  margin-bottom: 0px;
  font-family: "SourceSansPro-Regular";
}
.mat-cstm-icon{
  vertical-align: middle;
 }
 
@media (max-width: 768px) { 
  .login-page{
      width: 100%;
  }
  .auth-banner-text{
      display: none;
  }
 }
.password-block{
    display: block;
  
    .cstm-form-filed{
      width: 48%;
    }
    .form-m-l{
      margin-left: 10px;
    }
}
  @media (max-width: 768px) { 
    .form{
      max-width: 100%;
    }
   }
   
.progress-bar-wrapper{
  padding: 30px 0px;
}
.progress-wrapper {
  border: 1.5px solid #0F79AF;
  border-radius:8px !important;
  height: 10px !important;
}
  
.progress-bar-wrapper .mat-progress-bar-fill::after {
  background-color: #0F79AF !important;
  border-radius: 10px !important;
}
.progress-wrapper .mat-progress-bar-buffer {
  background-color: #F6FBFF !important;
}
.error-wrapper{
  background-image: url(../../../assets/images/pages/errorpage-404.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: 50%;
  width: 100%;
  height: 100vh;
}
.error-page-text{
  width: 32%;
  text-align: center;
  margin-left: auto;
  margin-right: 58px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.error-page-text h2{
    font-size: 36px;
    line-height: 50px;
    font-family: "SourceSansPro-Bold";
    margin-bottom: 0px;
  }
   .error-page-text p{
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0px;
    font-family: "SourceSansPro-SemiBold";
   }
@media (max-width: 768px) { 
    .error-page-text {
      width: 100%;
    }
  }
  @media (max-width: 425px) { 
    .error-page-text p{
      margin-top: 52%;
      font-size: 15px;
    }
    .error-page-text h2{
      font-size: 29px;
    }
  }
  .time-up-wrapper p{
    margin-bottom: 15px !important;
  }
  .main-layout{
    display: flex;
    background-color: #fff !important;
}
.side-bar{
    width: 20%;
    // height: 150vh;
    background-color: #F6FBFF;
}
.main-content{
    width: 80%;
    // height: 150vh;
}
.main-header{
    height: 64px;
    background-color: #0F79AF;
}
.content-wrapper{
    background-color: #fff;
    // height: calc(100vh - 64px);
    overflow-y: auto;

}
.profile-contact{
    width: 50px;
    height: 50px;
    margin: 7px 21px 0px ;
    background-color: #fff;
    vertical-align: middle;
    border-radius: 50%;
    float: right;

}
.text-blue{
   color: #0F79AF;
   font-size: 20px;
   text-align: left;
   line-height: 28px;
   letter-spacing: 1.2px;
   opacity: 1;
   font-family: "SourceSansPro-SemiBold";
}
.skip-wrapper{
    color: #0F79AF;
    font-size: 14px !important;
    line-height: 20px;
    font-family: "SourceSansPro-SemiBold";
}
.skip-wrapper:hover{
    color: #0F79AF;
    background-color: #fff !important;
}
.onbording-contact{
    // padding: 30px 20px;
    border: 1px solid #0F79AF;
    border-radius: 8px;
    opacity: 1;
    margin: 20px;
  
}
.overtap-contact {
   position: relative;
   top: -11px;
   left: 20px;
}
.overtap-contact p{
   background-color: #fff;
   padding: 0px 10px;
   width: fit-content;
   text-align: center;
   color: #0F79AF;
   font-family: "SourceSansPro-Bold";
   margin-bottom: 0px !important;
   font-size: 16px;
   letter-spacing: 0.32px;
   line-height: 22px;
   opacity: 1;
   display: block;
}
.street-wrapper{
    font-family: "SourceSansPro-Bold";
    text-align: left;
    letter-spacing: 0.28px;
    color: #4B4B4B;
    font-size: 14px;
}
.stepper-container{
  .mat-stepper-vertical {
    background-color: #F6FBFF;
  }
  .mat-vertical-content {
    padding: 0px;
}
.mat-step-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  position: relative;
  font-size: 18px;
}
 
.mat-step-icon .mat-icon{
  color: #fff;
  background-color: #198754;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
 
}
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #0F79AF;
  color: #fff;
}
// .mat-step-header .mat-step-icon {
//   background-color: transparent;
//   color: #4B4B4B;
//   border: 1px solid #4B4B4B;
//   border-radius: 50%;
// }

.mat-stepper-vertical-line::before{
  left: 3px;
}
}
.mat-dialog-container {
  display: flex !important;
  align-items: center;
  padding: 24px 70px !important;
}
.chip1-wrapper {
  .mat-chip.mat-standard-chip {
    background-color: #FFFFFF;
    color: #4B4B4B;
    border: 1px solid #4B4B4B;
  }
  .mat-chip.mat-standard-chip::after{
    background-color: #ffffff;
  }
  .mat-standard-chip {
    padding: 7px 15px;
  }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{
    background-color: #F6FBFF;
    color: #0F79AF;
    border: 1px solid #0F79AF;
  }
}
